<template>
  <div class="box">
    <van-nav-bar
        title="创建检查任务"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
     />
    <div style="height: 20px"></div>
    <div class="duoxuan" v-if="step==2">
      <div class="duoxuanC">
        <div style="margin-bottom: 5px">选择检查项</div>
        <div class="item" v-for="x in ScoreList"><van-checkbox v-model="x.checked">{{x.content}}</van-checkbox></div>
      </div>
    </div>
    <van-form >
      <div v-show="step==1">
      <van-cell-group inset>
        <van-field
          v-model="form.code"
          label="任务编号"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field name="radio" label="检查类型">
          <template #input>
            <van-radio-group v-model="form.type" direction="horizontal">
              <van-radio :name="30">飞行检查</van-radio>
              <van-radio :name="40">亚运检查</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </van-cell-group>
      <van-cell-group inset>
        <van-field
          v-model="form.startDateTime"
          is-link
          readonly
          name="datePicker"
          label="开始时间"
          placeholder="点击选择时间"
          @click="handleDatePicker('startDateTime')"
        />
        <van-field
          v-model="form.endDateTime"
          is-link
          readonly
          name="datePicker"
          label="结束时间"
          placeholder="点击选择时间"
          @click="handleDatePicker('endDateTime')"
          :rules="[{ required: true, message: '请点击选择时间' }]"
        />
      </van-cell-group>
      <van-cell-group inset>
        <van-field
          readonly
          clickable
          is-link
          name="picker"
          :value="form.storeName"
          label="选择企业"
          placeholder="点击选择企业"
          @click="showStorePicker = true"
          :rules="[{ required: true, message: '请点击选择企业' }]"
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="form.memberOneName"
          label="检查人员"
          placeholder="点击选择检查人员"
        />
        <van-field
          readonly
          clickable
          is-link
          name="picker"
          :value="form.memberTwoName"
          label="检查人员"
          placeholder="点击选择检查人员"
          @click="showMemberPicker = true"
          :rules="[{ required: true, message: '请点击选择检查人员' }]"
        />
      </van-cell-group>
      </div>
      <div style="margin: 16px;">
        <van-button round block type="info" v-if="step==2" @click="step=1" style="margin-bottom: 20px">上一步</van-button>
        <van-button round block type="info" v-if="step==1" @click="check">下一步</van-button>
        <van-button round block type="info" v-if="step==2" @click="onSubmit">提交</van-button>
<!--        <van-button round block type="info" native-type="submit" v-if="step==2">提交</van-button>-->
      </div>
    </van-form>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="datetime"
        title="选择完整时间"
        :formatter="formatter"
        :min-date="minDate"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showStorePicker" position="bottom">
      <van-field
        placeholder="请输入企业名称查询"
        v-model="searchStore"
        left-icon="search"
        clearable
        @input="handleSearchNations"
      />
      <van-picker
        show-toolbar
        :columns="columns"
        value-key="name"
        @confirm="onConfirmStore"
        @cancel="showStorePicker = false"
      />
    </van-popup>
    <van-popup v-model="showMemberPicker" position="bottom">
      <van-field
        placeholder="请输入姓名查询"
        v-model="searchMember"
        left-icon="search"
        clearable
        @input="handleSearchMember"
      />
      <van-picker
        show-toolbar
        :columns="memberColumns"
        value-key="trueName"
        @confirm="onConfirmMember"
        @cancel="showMemberPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Form, Field, CellGroup, Radio, RadioGroup, NavBar, DatetimePicker, Popup, Button, Picker, Toast,Checkbox } from 'vant'
Vue.use(NavBar)
Vue.use(Form)
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(Button)
Vue.use(Picker)
Vue.use(Toast)
Vue.use(Checkbox)

import { storeList, getMember, createTaskApi } from '@/api/get'
import {scoreList} from "../../api/get";

export default {
  name: 'RegulatoryH5Add',

  data() {
    return {
      ScoreList:[],
      step:1,
      form: {
        code: '',
        type: 30,
        startDateTime: '',
        endDateTime: '',
        storeId: '',
        storeName: '',
        memberId: '',
        memberOne: '',
        memberOneName: '',
        memberTwo: '',
        memberTwoName: ''
      },
      showPicker: false,
      selectField: '',
      showStorePicker: false,
      showMemberPicker: false,
      minDate: new Date(),
      columns: [],
      memberColumns: [],
      searchStore: '',
      searchMember: ''
    }
  },
  created() {
    this.scoreList()
    this.loadStoreOptions()
    this.loadMemberOptions()
    this.form.memberOne = localStorage.userId
    this.form.memberOneName = localStorage.name + ' - ' + localStorage.userName
  },
  mounted() {
    this.form.code = new Date().getTime()
    this.form.startDateTime = this.timeFormat(new Date())
  },

  methods: {
    check(){
      console.log(this.form)
      if(!this.form.endDateTime){
        Toast('请选择结束时间');
        return
      }
   /*   if(!this.form.storeName){
        Toast('请选择企业');
        return
      }*/
      if(!this.form.memberTwoName){
        Toast('请选择检查人员');
        return
      }
      this.step=2

    },
    async scoreList(){
      let rs=await scoreList()
      if(rs.data.code==200){
this.ScoreList=rs.data.data
      }
    },
    onClickLeft() {
      this.$router.push('/home')
    },
    loadStoreOptions() {
      storeList().then(res => {
        this.columns = res.data.data
      })
    },
    loadMemberOptions() {
      let regulatoryId=localStorage.storeId
      getMember({regulatoryId:regulatoryId}).then(res => {
        this.memberColumns = res.data.data.filter(x => x.id != localStorage.userId)
      })
    },
    handleSearchNations() {
      this.columns = this.columns.filter(x => x.name.indexOf(this.searchStore) > -1)
    },
    handleSearchMember() {
      this.memberColumns = this.memberColumns.filter(x => x.name.indexOf(this.searchMember) > -1)
    },
    handleDatePicker(field) {
      this.showPicker = true
      this.selectField = field
    },
    onConfirm(val) {
      this.showPicker = false
      this.form[this.selectField] = this.timeFormat(val)
    },
    onConfirmStore(val) {
      this.form.storeId = val.id
      this.form.storeName = val.name
      this.showStorePicker = false
    },
    onConfirmMember(val) {
      this.form.memberTwo = val.id
      this.form.memberTwoName = val.trueName + ' - ' + val.phone
      this.showMemberPicker = false
    },
    onSubmit() {
      this.form.scoreItems=this.ScoreList.filter(x=>x.checked==true)
      this.form.memberId = [this.form.memberOne, this.form.memberTwo]
      createTaskApi(this.form).then(res => {
        if (res.data.code === 200) {
          Toast('任务创建成功')
          this.$router.push('/home')
        }
        if(res.data.code === 400){
          Toast(res.data.msg)
        }
      })
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    timeFormat(time) { // 时间格式化 2019-09-08
      let year = time.getFullYear()
      let month = this.repairZero(time.getMonth() + 1)
      let day = this.repairZero(time.getDate())
      let hh = this.repairZero(time.getHours())
      let mm = this.repairZero(time.getMinutes())
      return year + '-' + month + '-' + day + ' '+ hh + ':' + mm + ':00'
    },
    repairZero(val) {
      return val < 10 ? '0' + val : val
    }
  },
};
</script>

<style scoped>
.duoxuan{padding: 15px;}
.duoxuanC{background-color: white;border-radius: 10px;padding: 10px}
>>>.van-checkbox{margin-bottom: 10px;text-align: left}

.box {
  width: 100%;
  height: 100%;
  background-color: #f7f8fa;
}
</style>